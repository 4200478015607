import React, { useState } from 'react';
// material
import {
  experimentalStyled as styled,
  makeStyles
} from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  Collapse,
  ListItemText,
  Link
} from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    color: 'rgba(255,255,255,0.8)',
    '&:hover': {
      color: '#fff'
    }
  },
  menuLink: {
    textDecoration: 'none',
    color: 'rgba(102,102,102,0.85)'
  },
  dropdownLink: {
    textDecoration: 'none',
    color: '#666'
  },
  icon: {
    color: 'white'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '260px',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: 'rgba(255,255,255,0.95)',
    color: 'black'
  },
  appMenu: {
    width: '100%'
  },
  menuItem: {
    width: '260px',
    backgroundColor: 'transparent',
    color: 'rgba(102,102,102,0.85) !important',
    padding: '15px',
    borderTop: '1px solid #ececec',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#ddd'
    }
    // borderBottom: '1px solid rgba(255, 255, 255, 0.12)'
  },
  menuItemIcon: {
    color: '#fff'
  },
  menuItemText: {
    '& span': {
      fontSize: '15px !important',
      fontFamily: 'Source Sans Pro!important',
      fontWeight: '600'
    }
  },
  collapse: {
    '& .MuiListItemText-root': {
      paddingLeft: '20px',
      '& span': {
        fontSize: '16px',
        fontFamily: 'Source Sans Pro',
        fontWeight: '300',
        color: '#666'
      }
    }
  }
}));

const RootStyle = styled('div')(() => ({}));

export default function Sidebar() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { isAuthenticated } = useAuth();

  const [open1, setOpen1] = useState(false);

  const handleAudits = () => {
    setOpen1(!open1);
  };

  const [openBuy, setOpenBuy] = useState(false);

  const handleBuy = () => {
    setOpenBuy(!openBuy);
  };

  const [openCoin, setOpenCoin] = useState(false);

  const handleCoin = () => {
    setOpenCoin(!openCoin);
  };

  // const [openPress, setOpenPress] = useState(false);

  // const handlePress = () => {
  //   setOpenPress(!openPress);
  // };

  const [openDownload, setOpenDownload] = useState(false);

  const handleDownload = () => {
    setOpenDownload(!openDownload);
  };
  return (
    <RootStyle>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <List component="nav" className={classes.appMenu} disablePadding>
          <ListItem button onClick={handleAudits} className={classes.menuItem}>
            <ListItemText primary="KYC" className={classes.menuItemText} />
            {open1 ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse
            in={open1}
            timeout="auto"
            unmountOnExit
            className={classes.collapse}
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://github.com/solidproof/kyc-certificates/raw/main/KYC_Certificate_IncomeIsland.png"
                  target="_new"
                >
                  <ListItemText
                    inset
                    primary="Search / Verify on solidproof.io"
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://twitter.com/SolidProof_io/status/1466843558976139269"
                  target="_new"
                >
                  <ListItemText inset primary="Official Twitter" />
                </Link>
              </ListItem>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://github.com/solidproof/kyc-certificates/raw/main/KYC_Certificate_IncomeIsland.png"
                  target="_new"
                >
                  <ListItemText inset primary="KYC Certificate" />
                </Link>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={handleBuy} className={classes.menuItem}>
            <ListItemText
              primary="Island Shop"
              className={classes.menuItemText}
            />
            {openBuy ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse
            in={openBuy}
            timeout="auto"
            unmountOnExit
            className={classes.collapse}
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://incomeisland.org/income-coin/"
                  target="_new"
                >
                  <ListItemText inset primary="Income Multi Chain Coin" />
                </Link>
              </ListItem>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://incomeisland.org/buy-income-island/"
                  target="_new"
                >
                  <ListItemText inset primary="OG INCOME token" />
                </Link>
              </ListItem>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://incomeisland.app/buy/"
                  target="_new"
                >
                  <ListItemText inset primary="Buy VRE Virtual Real Estate" />
                </Link>
              </ListItem>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://incomeisland.org/NFT/"
                  target="_new"
                >
                  <ListItemText
                    inset
                    primary="Income Island Iguana Club NFT Collection"
                  />
                </Link>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button className={classes.menuItem}>
            <Link
              className={classes.menuLink}
              underline="none"
              href="https://incomeislandllc.gitbook.io/income-island-whitepaper/"
              target="_new"
            >
              <ListItemText
                primary="Whitepaper"
                className={classes.menuItemText}
              />
            </Link>
          </ListItem>
          <ListItem button onClick={handleCoin} className={classes.menuItem}>
            <ListItemText
              primary="Income Coin"
              className={classes.menuItemText}
            />
            {openCoin ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse
            in={openCoin}
            timeout="auto"
            unmountOnExit
            className={classes.collapse}
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://poocoin.app/tokens/0x75ef7e9028798b4deaa10ac8348dfe70b770325c/"
                  target="_new"
                >
                  <ListItemText inset primary="Buy with BNB - LIVE" />
                </Link>
              </ListItem>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0xc1c8b34b395659d7eb962f88bbf0e40209ad83b0/"
                  target="_new"
                >
                  <ListItemText inset primary="Buy with ETH - LIVE" />
                </Link>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button className={classes.menuItem}>
            <Link
              className={classes.menuLink}
              underline="none"
              href="https://apps.microsoft.com/store/detail/income-island-metaverse-beta/9NCVR44X7Q5C/"
              target="_new"
            >
              <ListItemText
                primary="Download (PC)"
                className={classes.menuItemText}
              />
            </Link>
          </ListItem>
          <ListItem
            button
            onClick={handleDownload}
            className={classes.menuItem}
          >
            <ListItemText
              primary="Downloads"
              className={classes.menuItemText}
            />
            {openDownload ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse
            in={openDownload}
            timeout="auto"
            unmountOnExit
            className={classes.collapse}
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://incomeisland.app/Income-Island-Metaverse-Demo.exe"
                  target="_new"
                >
                  <ListItemText inset primary="Download for PC" />
                </Link>
              </ListItem>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://incomeisland.app/Income-Island-Metaverse-Demo.dmg"
                  target="_new"
                >
                  <ListItemText inset primary="Download for MacOS" />
                </Link>
              </ListItem>
              <ListItem button className={classes.menuItem}>
                <Link
                  className={classes.dropdownLink}
                  underline="none"
                  href="https://apps.microsoft.com/store/detail/income-island-metaverse-beta/9NCVR44X7Q5C/"
                  target="_new"
                >
                  <ListItemText inset primary="Download from Microsoft Store" />
                </Link>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button className={classes.menuItem}>
            <RouterLink
              variant="contained"
              to="/dashboard"
              className={classes.menuLink}
            >
              <ListItemText
                primary="Dashboard"
                className={classes.menuItemText}
              />
            </RouterLink>
          </ListItem>
          <ListItem button className={classes.menuItem}>
            <Link
              className={classes.menuLink}
              underline="none"
              href="https://incomeisland.org/llc/"
              target="_new"
            >
              <ListItemText primary="LLC" className={classes.menuItemText} />
            </Link>
          </ListItem>
          <ListItem button className={classes.menuItem}>
            <RouterLink
              variant="contained"
              to="/bridge"
              className={classes.menuLink}
            >
              <ListItemText primary="Bridge" className={classes.menuItemText} />
            </RouterLink>
          </ListItem>
          {!isAuthenticated && (
            <ListItem button className={classes.menuItem}>
              <RouterLink
                variant="contained"
                to="/auth/login"
                className={classes.menuLink}
              >
                <ListItemText
                  primary="Log in"
                  className={classes.menuItemText}
                />
              </RouterLink>
            </ListItem>
          )}
          {!isAuthenticated && (
            <ListItem button className={classes.menuItem}>
              <RouterLink
                variant="contained"
                to="/auth/register"
                className={classes.menuLink}
              >
                <ListItemText
                  primary="Register"
                  className={classes.menuItemText}
                />
              </RouterLink>
            </ListItem>
          )}
        </List>
      </Drawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        className={classes.menuIcon}
      >
        <MenuIcon />
      </IconButton>
    </RootStyle>
  );
}
